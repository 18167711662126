







































import { Toast } from "vant"
import HeadTop from "@/components/ShareComponent/HeadTop.vue"
import { Vue,Component,Ref } from "vue-property-decorator"
import ZoomPage from "@/impView/PageSafety";

interface ListItem<T=string>{
    text:T;
    content:T;
    copy:boolean
}

interface AboutType<T=string>{
    IcoIcon:any;
    AboutIcon:any;
    List:Array<ListItem>;
    Footer:{
        Title:T;
        Cont:T;
    };
    loadingShow:boolean

    handleCopy(data:ListItem):void;
}

@Component({ name:"About",components:{ HeadTop } })
export default class About extends ZoomPage implements AboutType{
    IcoIcon = require("../../assets/ico/Ico.png");
    AboutIcon = require("../../assets/icon/Basics/About.png");
    List = [
       {
           text:"客服电话",
           content:"0371-55880688",
           copy:true
       },{
           text:"公众号",
           content:"三易校园",
           copy:false
       },{
           text:"官方微博",
           content:"杭州三易科技",
           copy:false
       },{
           text:"QQ交流群",
           content:"820198366",
           copy:true
       },{
           text:"客服微信",
           content:"17324818788",
           copy:true
       },
    ]
    Footer = {
        Title:"用户协议",
        Cont:"杭州三易科技有限公司"
    }
    loadingShow=true

    mounted() {
        this.loadingShow = false
    }
    @Ref("input")
    Input!:HTMLInputElement;

    get GetInput(){
        return this.Input
    }

    handleCopy(data:ListItem){
        if( data.copy ){
           this.GetInput.value = data.content
           this.GetInput.select()
           document.execCommand("copy");
           Toast({
               message:"复制成功",
               icon:"none",
               className:"ToastClassName"
           })
        }
    }
}
